import React, {Suspense, useState, useEffect } from 'react';
import Privacy from './privacy';
import Terms from './terms';

export default function SateiStart1(props){
  const [errorMessage, setErrorMsg] = useState();
  const [trigger, setTrigger] = useState(false);
  const [agentList, setAgentList] = useState(props.steps.getAgents.value.find(a => a.name === 'agents'));
  const [checkedItems, setCheckedItems] = useState({});
  const [activityChanged, setActivityChanged] = useState(false); //useState(true);
  
  const triggerNext = (event) =>{
    setTrigger(props.triggerNextStep())
  }

  const handleSubmit = (event) => {
    event.preventDefault();
     let upData = [];
    const urlRefValue = document.URL;
  
upData = upData.concat({ name: 'url', value: urlRefValue });
    upData  = upData.concat(props.steps.getAgents.value);
    upData  = upData.concat(props.steps.contactInfo.value);
    upData  = upData.concat(props.steps.userInfo.value);
    let idData = upData.find(a => a.name === 'id');
    let str = idData.value;

    let agentChecksPre = document.getElementsByClassName("agentChecks");
    let agentIdsPre = document.getElementsByClassName("agentIds");
    let agentMailsPre = document.getElementsByClassName("agentMails");
    let agentSubMailPres = document.getElementsByClassName("agentSubMails");

    var agentIds = Array.prototype.slice.call(agentIdsPre);
    var agentMails = Array.prototype.slice.call(agentMailsPre);
    var agentSubMails = Array.prototype.slice.call(agentSubMailPres);
    var agentChecks = Array.prototype.slice.call(agentChecksPre);

    let neArr = Object.entries(checkedItems).map(([key, value]) => ({key, value}))
    let checklfg = 0;
    neArr.forEach(element => {
      if(element.value === true){
        checklfg++;
        let num = element.key.slice(-1);upData = upData.concat({name: agentIds[num].id , value: agentIds[num].value});
        upData = upData.concat({name: agentMails[num].id , value: agentMails[num].value});
        upData = upData.concat({name: agentSubMails[num].id , value: agentSubMails[num].value});
        upData = upData.concat({name: agentChecks[num].id , value: agentChecks[num].value});
     
      }
    });
    if(checklfg !== 0){setErrorMsg(''); }else{
      setErrorMsg('※査定会社を１社以上選択して下さい');
      return false;
    };
  props.step.value = upData;
  var jsonData = JSON.stringify(upData);
    fetch("/mailer1",{
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      body: jsonData
    })
    .then((res) => {
      if(res == 'error'){
      alert('追加情報送信に失敗しました3');
      }else{
        const location = window.location;
        location.href = '/lp2/form2.html?id='+ encodeURIComponent(str);
      };
    })
    .catch((err) => {
      alert('追加情報送信に失敗しました4');
    });
    triggerNext();
  };


  const setDefaultCheck = () => {
    let checkBoxList = document.getElementsByClassName("agentChecks");
    var checksArray = Array.prototype.slice.call(checkBoxList);
    var tempObj = {};
    for (let i = 0; i < checksArray.length; i += 1) {
         checksArray[i].checked = true;
          tempObj[checksArray[i].id] = checksArray[i].checked;
      }
    setCheckedItems(tempObj);
  }

  const checkChange = e => {
        setCheckedItems({
          ...checkedItems,
          [e.target.id]: e.target.checked
        })
  }

  useEffect(() => {
  setDefaultCheck();
  },[]);

  return (
    <form onSubmit={handleSubmit} className="formCss3 sateiStart back-white-wrapper">
      <label><p>査定依頼先</p><div className="form-check">
      {agentList.value?.map((d , index) =>{ return <>
      <div className='agentInfo-wrapper'>
      <div className="checkbox-wrapper agent-checkbox">
      <label className="agent-label">
      <input type="hidden" className="agentIds" name={`agent-id${index}`} value={d.id} id={`agent-id${index}`} />
      <input type="hidden" className="agentMails" name={`agent-mail${index}`} value={d.mail} id={`agent-mail${index}`} />
      <input type="hidden" className="agentSubMails" name={`sub-mail${index}`} value={d.sub_mail} id={`sub-mail${index}`} />
      <input type="checkbox" className="agentChecks" name={`agent${index}`} value={d.name} id={`agent${index}`} onChange={checkChange} checked={checkedItems[d.id]}/>
      <img className="agent-img" src={`../assets/img/agentlogo/${d.id}.png`} alt="画像"></img>
      </label><br></br>
      
      <h2>{d.name}</h2>
      </div>
      <div style={{border:'1px solid #ccc',height:'50px',overflow:'auto',width:'90%',borderRadius:'3px',backgroundColor:'#fff',margin:'auto',padding:'10px',fontSize: 'initial',lineHeight:'normal'}}>
          <p>{d.discription}.</p>
      </div>
      </div>
      </>})}
          </div>
          </label><br></br><br></br>
        <div className='privacy-wrapper'>
          <p>プライバシーポリシー</p>
          <div>
          <Privacy/>
          </div>
          <p><br></br></p>
          <p>利用規約</p>
          <div>
          <Terms/>
          </div>
          <p><br></br></p>
          <div>
          <p className="center">プライバシーポリシー・利用規約に<br className='spBr'></br>同意して、査定依頼をする</p>
          </div>
          <p className='errMsg'>{errorMessage}</p>
        <button className="btn btn-success satei-start-button" disabled ={(activityChanged)?true:false}>
            無料査定スタート
            </button>
            </div>
      </form>
    );
  }